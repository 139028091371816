import React from 'react';
import { CircularProgress } from '@material-ui/core';
import Banner from '../../../atoms/Banner/banner';
import StyledWrapper, {
  BannerWrapper,
  ContentListingContainer,
  ContentListingHeader,
  MainInfoCircularProgress,
  ContentListingLine,
  ContentListingMain,
} from './StyledLoader.js';

const Loader = () => (
  <StyledWrapper>
    <ContentListingContainer>
      <ContentListingContainer>
        <Banner />
        <ContentListingMain>
          <ContentListingHeader>Loading...</ContentListingHeader>
          <ContentListingLine />
          <MainInfoCircularProgress>
            <CircularProgress size={80} thickness={5} />
          </MainInfoCircularProgress>
        </ContentListingMain>
      </ContentListingContainer>
    </ContentListingContainer>
  </StyledWrapper>
);

export default Loader;
