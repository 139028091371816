import React from 'react';
import { Box, Image } from 'rebass/styled-components';
import BannerImage from '../../../assets/group-11.svg';

const Banner = () => (
  <Box className="banner" width={1} sx={{ position: 'absolute', zIndex: -2, left: 0, top: 80, overflow: 'hidden' }}>
    <Image src={BannerImage} alt="banner" />
  </Box>
);

export default Banner;
