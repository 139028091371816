import styled from 'styled-components';

const StyledWrapper = styled.div`
  width: 100%;
  margin: 0;
`;
export const ContentListingHeader = styled.div`
  font-size: 30px;
  align-self: center;
  width: 90%;
  padding-top: 63px;
  z-index: 1;
`;

export const MainInfoCircularProgress = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContentListingLine = styled.div`
  width: 90%;
  align-self: center;
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
`;

export const ContentListingMain = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-self: center;
  align-items: center;
  margin-top: 8%;
  width: 75%;
  min-height: 300px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 3px 8px 0 rgba(106, 126, 150, 0.14);
  max-width: 1170px;
`;
export const BannerWrapper = styled.div`
  height: 332px;
  top: 104px;
  position: absolute;
  z-index: -2;
`;
export const ContentListingContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  font-family: Heebo;
  color: #4c6372;
  font-weight: 300;
`;

export default StyledWrapper;
